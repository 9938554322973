
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import LoginFormCard from '@/components/LoginFormCard.vue';
    import {
        IUser,
    } from '@/types';

    const accountNamespace = namespace('account');

    @Component<ViewLogin>({
        components: {
            LoginFormCard,
        },
    })
    export default class ViewLogin extends Vue {
        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser|null;

        public onLogged() {
            if (this.loggedUser?.account?.type === 'veterinarian') {
                this.$router.push({name: 'home'});
            } else {
                this.$router.push({name: 'appointments'});
            }
        }

        public goToRegister() {
            this.$router.push({name: 'register'});
        }
    }
